<template>
  <router-link :to="`/invoices/invoice/${item.id}`" class="block hover:bg-gray-100 dark:hover:bg-gray-770 rounded-lg">
    <div class="py-3 px-2">
      <div class="sm:flex flex-wrap lg:flex-nowrap items-center">

        <div class="grid grid-cols-12 w-full">
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
            <ItemText title="#" :text="item.uuid" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
            <ItemText title="Datums" :text="item.invoice_date" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
            <ItemText title="Summa EUR" :text="item.total" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
            <ItemText title="PVN EUR" :text="item.vat" />
          </div>
          <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
            <ItemText title="Kopā EUR" :text="item.total_with_vat" />
          </div>
        </div>

        <div class="ml-auto">
          <p class="mt-2 flex items-end h-full text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
            <Button icon="download" @click="downloadInvoice" />
          </p>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import {mapGetters} from "vuex";
import ItemText from "@/components/Components/ItemText"

export default {
  name: "SingleInvoiceEntry",
  components: {
    ItemText,
  },
  props: ['item'],
  computed: {
    ...mapGetters({
      loading: 'loading'
    }),
  },
  methods: {
    openInvoice() {
      this.$router.push('/invoices/invoice/' + this.item.id)
    },
    downloadInvoice(){
      this.$store.dispatch('getInvoicePdf', this.item)
    },
  }
}
</script>

<style>

</style>