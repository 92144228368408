<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Rēķini / PPR
      </h3>
    </template>

    <template v-slot:buttons>
      <template v-if="customerInvoices.length > 3">
        <Button :icon="showFullList ? 'chevron-up' : 'chevron-down'" @click="showFullList = !showFullList" />
      </template>
    </template>

    <template v-slot:content>

      <template v-if="invoices.length > 0">
        <div class="flex flex-col w-full">
          <SingleInvoiceEntry v-for="(item, index) in invoices" :key="index" :item="item" />
        </div>
      </template>

    </template>
  </item-card>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import SingleInvoiceEntry from "@/components/Invoices/SingleInvoiceEntry"

export default {
  name: "InvoiceList",
  components: {
    ItemCard,
    SingleInvoiceEntry,
  },
  props: ['customerInvoices'],
  data(){
    return {
      showFullList: false,
    }
  },
  computed:{
    invoices(){
      return this.showFullList ? this.customerInvoices : this.customerInvoices.slice(0, 3)
    }
  }
}
</script>

<style>

</style>